<script lang="ts" setup>
const { isLogged } = storeToRefs(useUser())
const { fetchCoins } = useCoins()
const { tokens } = storeToRefs(useCoins())
const { initExchange } = useSwapStore()

onMounted(() => fetchCoins())
</script>

<template>
  <!-- tokens table desktop -->
  <div hidden sm:block fubex-container my-48px>
    <table :data-test="getCypressId('tokens')" w-full>
      <tbody>
        <tr text-left text-neutral-700 paragraph-sm border-neutral-200 border-b-1>
          <th font-500 py-24px px-16px>
            Název kryptoměny
          </th>
          <th font-500 py-24px px-16px text-center>
            Cena
          </th>
          <th font-500 py-24px px-16px text-center>
            24h změna kurzu
          </th>
          <th font-500 py-24px px-16px text-center>
            Graf (7&nbsp;dní)
          </th>
          <th font-500 py-24px px-16px text-center>
            Směna
          </th>
        </tr>
        <tr v-for="token, i in tokens" :key="token.code" :data-test="getCypressId(`table-token-${token.code}`)" :class="i % 2 ? 'bg-neutral-50' : ''" hover:bg-neutral-100 dark:hover:bg-neutral-200>
          <td py-20px px-16px>
            <img :src="`/img/coins/${token.code}.svg`" :alt="token.name" w-32px inline mr-16px>
            <span font-600 mr-12px>{{ token.name }}</span>
            <span text-neutral-400>{{ token.code.toUpperCase() }}</span>
          </td>
          <td py-20px px-16px text-right whitespace-nowrap w-10px font-500>
            {{ formatPriceInCzk(token.showingPrice) }}
          </td>
          <td py-20px px-16px text-center :class="(token.change24HPercent ?? 0) < 0 ? 'text-coingecko-red' : 'text-coingecko-green'">
            {{ token.change24HPercent ? formatPercentChange(token.change24HPercent) : '' }}
          </td>
          <td py-20px px-16px text-center>
            <img v-if="token.chart" :src="token.chart" :alt="`${token.name} graf`" inline h-40px>
          </td>
          <td py-20px px-16px text-center>
            <FButton v-if="isLogged" :name="`swap-start-auth-${token.code}`" variant="outline" @click="initExchange(token)">
              Směnit
            </FButton>
            <WidgetAuthDialogs v-else default-view="login">
              <FButton :name="`swap-start-${token.code}`" variant="outline">
                Směnit
              </FButton>
            </WidgetAuthDialogs>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <!-- /tokens table desktop -->
  <!-- tokens table mobile -->
  <div sm:hidden>
    <table w-full>
      <tbody>
        <template v-for="token, i in tokens" :key="token">
          <tr :class="i % 2 ? 'bg-neutral-50' : ''">
            <td pt-16px px-16px>
              <img :src="`/img/coins/${token.code}.svg`" :alt="token.name" h-32px w-32px>
            </td>
            <td pt-16px font-600>
              {{ token.name }}
            </td>

            <td text-center px-16px text-neutral-400 pt-16px>
              7&nbsp;dní
            </td>
            <td text-center px-16px text-neutral-400 pt-16px>
              24h
            </td>
          </tr>
          <tr :class="i % 2 ? 'bg-neutral-50' : ''">
            <td>&nbsp;</td>
            <td text-left text-neutral-400>
              {{ token.code.toUpperCase() }}
            </td>
            <td px-16px text-center>
              <img :src="token.chart" :alt="`${token.name} graf`" w-full h-40px>
            </td>
            <td text-center pl-4px pr-16px whitespace-nowrap text-right :class="(token.change24HPercent ?? 0) < 0 ? 'text-coingecko-red' : 'text-coingecko-green'">
              {{ token.change24HPercent ? formatPercentChange(token.change24HPercent) : '' }}
            </td>
          </tr>
          <tr :class="i % 2 ? 'bg-neutral-50' : ''">
            <td>&nbsp;</td>
            <td>
              {{ formatPriceInCzk(token.showingPrice) }}
            </td>
            <td text-center colspan="2" p-16px>
              <FButton v-if="isLogged" :name="`swap-start-auth-${token.code}`" variant="outline" w-full @click="initExchange(token)">
                Směnit
              </FButton>
              <WidgetAuthDialogs v-else default-view="login">
                <FButton :name="`mobile-swap-start-${token.code}`" variant="outline" w-full>
                  Směnit
                </FButton>
              </WidgetAuthDialogs>
            </td>
          </tr>
        </template>
      </tbody>
    </table>
  </div>
  <!-- /tokens table mobile -->
</template>
