<script lang="ts" setup>
useHead({ title: '' })

const { isLogged } = storeToRefs(useUser())
</script>

<template>
  <div>
    <div fubex-container flex flex-col md:flex-row gap-16px>
      <HomeHero />

      <div
        :data-test="getCypressId('swap')"
        md:basis="1/2"
      >
        <div
          widgetswap-bg px-16px pt-32px pb-48px
          md="px-24px pt-92px rounded-br-90px" relative
          lg="px-64px"
        >
          <IndicatorNextFetch absolute top-0 left-0 />
          <WidgetSwap :force-narrow="true" />

          <div text-center>
            <FButton v-if="isLogged" name="swap-start" my-24px size="lg" w-full @click="$router.push('/profile/exchange')">
              Zahájit směnu
            </FButton>
            <WidgetAuthDialogs v-else default-view="login">
              <FButton name="swap-start-auth" my-24px size="lg" w-full>
                Zahájit směnu
              </FButton>
            </WidgetAuthDialogs>
          </div>
          <div flex justify-center gap-12px items-center paragraph-xs>
            <UnoIcon i-fubex-dot text-success-500 h-10px w-10px />
            Zabere Vám to zhruba 2 minuty
          </div>
        </div>
      </div>
    </div>
    <WidgetExchangeRates />
    <HomeCustomContent />
    <!-- newsletter -->
    <div :data-test="getCypressId('newsletter')" fubex-container max-w-6xl p-16px mb-48px>
      <WidgetNewsletter />
    </div>
    <!-- /newsletter -->
  </div>
</template>
